<template>
    <Toast :baseZIndex="1" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="areaList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Area</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Import" icon="pi pi-paperclip" class="p-mr-2" @click="onImportArea" />
                            <Button label="Add Area" icon="pi pi-plus" class="p-mr-2" @click="onAddArea" />
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                @click="onFilter()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash"
                                class="p-button-success" @click="onFilter()"></Button>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column header="Area" field="area" headerStyle="width: 60%">
                        <template #body="{ data: { mam3 } }">
                            <div class="p-text-capitalize">
                                <span :class="'user-badge status-temp-suspend'">{{ mam3 || 'N/A' }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Pincode" field="pincode" headerStyle="width: 25%">
                        <template #body="{ data: { mam4 } }">
                            <div class="p-text-capitalize">{{ mam4 || 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded " @click="onEditArea(data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- add area dialog start here -->
    <Dialog v-model:visible="isAddArea" :style="{ width: '800px' }" header="Add Area" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="city">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="city" v-model="city" :options="cityList" optionLabel="label" placeholder="Select City"
                        :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.city.$error">{{
        v$.city.$errors[0].$message
    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="area-name">
                        Area
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="area-name" class="p-text-capitalize" v-model.trim="area" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': isSubmitted && !area }" />
                    <small class="p-invalid p-error" v-if="v$.area.$error">{{
        v$.area.$errors[0].$message
    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="pin-code">
                        Pin-code
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="pin-code" v-model.trim="pincode" required="true" maxlength="6" autofocus
                        :class="{ 'p-invalid': isSubmitted && !pincode }" />
                    <small class="p-invalid p-error" v-if="v$.pincode.$error">{{
        v$.pincode.$errors[0].$message
    }}</small>
                </div>

            </div>
        </div>
        <template #footer>
            <div>
                <Button label="Submit" :icon="`pi ${!showLoader ? 'pi-check' : 'pi-spin pi-spinner indigo-color'}`"
                    class="p-button-text p-ml-auto" @click="addAreaBtn()" style="width: 90px"></Button>
                <!-- <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button> -->
            </div>
        </template>
    </Dialog>
    <!-- add area dialog end here -->

    <!-- edit Gram Panchayat dialog start here -->
    <Dialog v-model:visible="isEditArea" :style="{ width: '800px' }" header="Edit Area" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="city-area">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="city-area" v-model="city" :options="cityList" optionLabel="label"
                        placeholder="Select City" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.city.$error">{{
        v$.city.$errors[0].$message
    }}</small>
                </div>

                <div class="p-field p-col-12 p-md-6">
                    <label for="area">
                        Area
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="area" class="p-text-capitalize" v-model.trim="area" required="true"
                        @keypress="onlyNumber" maxlength="20" autofocus
                        :class="{ 'p-invalid': isSubmitted && !area }" />
                    <small class="p-invalid p-error" v-if="v$.area.$error">{{
        v$.area.$errors[0].$message
    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="area-pincode">
                        Pin Code
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="area-pincode" class="p-text-capitalize" v-model.trim="pincode" required="true"
                        @keypress="onlyNumber" maxlength="6" autofocus
                        :class="{ 'p-invalid': isSubmitted && !pincode }" />
                    <small class="p-invalid p-error" v-if="v$.pincode.$error">{{
        v$.pincode.$errors[0].$message
    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="editAreaBtn()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit Gram Panchayat dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="isFilterDialog" :style="{ width: '600px' }" header="Filter" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="city-filter">City</label>
                    <MultiSelect v-model="cityFilter" id="city-filter" :options="cityList" optionValue="value"
                        optionLabel="label" placeholder="Select City" :filter="true" class="multiselect-custom"
                        appendTo="body"></MultiSelect>
                </div>

                <div class="p-field p-col-12">
                    <label for="area">Area</label>
                    <InputText v-model.trim="areaFilter" placeholder="Enter Area" maxlength="20"></InputText>
                </div>
                <div class="p-field p-col-12">
                    <label for="pincode">Pin Code</label>
                    <InputText v-model="pincodeFilter" id="pincode" placeholder="Enter Pin-code" maxlength="6"
                        @keypress="onlyNumber"></InputText>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(cityFilter, pincodeFilter, areaFilter)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->

    <!-- import csv dialog start here -->
    <Dialog v-model:visible="isImportArea" :style="{ width: '1100px' }" header="Import Area" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" align="left">
                    <FileUpload mode="basic" ref="importareafile" class="custom-upload-btn-ultima"
                        v-model="importareafile" v-on:change="handleUploadImport()" />
                    <div style="color:red" v-if="importmsg.file">
                        {{ importmsg.file }}
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6" align="right">
                    <label class="p-mr-2">
                        Download Sample Sheet:
                    </label>
                    <!-- TODO: Need CSV File URL here -->
                    <router-link href="https://storage.googleapis.com/meraneta_admin/import_Area_data.csv"
                        target="_blank" to="#">
                        <Button icon="pi pi-download" v-tooltip.left="'Download Sample Sheet'" class="p-button-rounded">
                        </Button>
                    </router-link>
                </div>
                <div class="p-field p-col-12">
                    <h5 class="indigo-color p-m-0 p-pl-1">Note:</h5>
                    <ol style="line-height: 1.5;">
                        <li>The file name should be same as sample file name "import_Area_data.csv".</li>
                        <li>Do not just change any files extension from .XLS or any other extension to CSV and upload
                            it.
                        </li>
                        <li>You can upload enquiries in one sheet up to 5000 enquiries in one attempt.</li>
                        <li>Don't change and delete any column name in CSV sheet.</li>
                        <li>Date format must be in "dd-mm-yyyy".</li>
                        <li>Insert employee mobile number properly to allocate the enquiry to a particular employee,
                            else
                            enquiry will be inserted into logged-in employees BMS account.</li>
                        <li>Project name, unit type & source must be the same as in the BMS System.</li>
                        <li>In CSV sheet enquiry date, first name or last name, mobile or email, employee mobile, source
                            column's are mandatory.</li>
                        <li>Submitted sheets for importing area data till 10 pm will be executed on the same night and
                            the
                            result will be available by 7 am next day</li>
                    </ol>
                </div>
            </div>
        </div>
        <template #footer>
            <Button v-if="!importfileloader" label="Submit" icon="pi pi-check" class="p-button-text" :disabled="importmsg.file != '' ||
        importareafile == null ||
        importareafile == ''
        " @click="onCSVSubmit" style="width: 90px" />
            <Button v-show="importfileloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- import dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            areaList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            isAddArea: false,
            city: '',
            cityList: [],
            area: '',
            isImportArea: false,
            showLoader: false,
            isSubmitted: false,
            row_id: null,
            isFilterDialog: false,
            showFillFilter: false,
            isEditArea: false,
            importfileloader: false,
            importmsg: [],
            importareafile: "",
            pincodeFilter: '',
            cityFilter: '',
            areaFilter: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getAreaList();
        this.getAllCities();
    },
    validations() {
        return {
            city: { required: helpers.withMessage('Please select city', required) },
            pincode: { required: helpers.withMessage('Please enter pin-code', required) },
            area: { required: helpers.withMessage('Please enter area', required) },
        };
    },
    methods: {
        getAllCities(ev) {
            this.ApiService.getAllCities(ev).then((data) => {
                if (data.success === true) {
                    this.cityList = data.data;
                } else {
                    this.cityList = null;
                }
            });
        },
        handleUploadImport() {
            var file = this.$refs.importareafile.files[0];
            let allowedExtensions = /(\.csv)$/i;
            if (!file) {
                this.importmsg["file"] = "Please upload CSV excel file.";
            } else if (!file.size > 2048) {
                this.importmsg["file"] = "File size cannot exceed more than 2MB";
            } else if (!file.name.match(allowedExtensions)) {
                this.importmsg["file"] = "Invalid file type";
            } else {
                this.importmsg["file"] = "";
                this.importareafile = this.$refs.importareafile.files[0];
            }
        },
        onImportArea() {
            this.isImportArea = true;
        },
        onAddArea() {
            try {
                this.isAddArea = true;
                this.city = '';
                this.pincode = '';
                this.area = '';
                this.showLoader = false;
                setTimeout(() => {
                    this.v$.$reset();
                }, 0);
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            }
        },
        addAreaBtn() {
            this.v$.$validate();
            let fields = {};
            if (this.city.value) {
                fields["mam2"] = this.city.value;
            }
            fields["mam4"] = this.pincode;
            fields["mam3"] = this.area;
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.addUpdateArea(fields).then((items) => {
                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.isAddArea = false;
                        this.showLoader = false;
                        this.city = '';
                        this.pincode = '';
                        this.area = '';
                        this.getAreaList();
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },
        onEditArea(e) {
            try {
                this.isEditArea = true;
                this.city = '';
                this.pincode = '';
                this.area = '';
                this.showLoader = false;
                this.row_id = e.mam1;
                this.pincode = e.mam4;
                this.area = e.mam3;
                let mam2 = this.cityList.filter(function (item) {
                    return item.value == e.mam2;
                });
                if (mam2.length > 0) {
                    this.city = mam2[0];
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            }
        },
        editAreaBtn() {
            this.v$.$validate();
            let fields = {};
            fields["mam1"] = this.row_id;
            if (this.city.value) {
                fields["mam2"] = this.city.value;
            }
            fields["mam4"] = this.pincode;
            fields["mam3"] = this.area;
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.addUpdateArea(fields).then((items) => {
                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.isEditArea = false;
                        this.showLoader = false;
                        this.city = '';
                        this.pincode = '';
                        this.area = '';
                        this.getAreaList();

                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                        this.showLoader = false;
                    }
                });
            }
        },
        onCSVSubmit() {
            this.importfileloader = true;
            var formData = new FormData();
            formData.append("importfile", this.importareafile);
            this.ApiService.importAreaData(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.isImportArea = false;
                    this.getAreaList();
                    this.importfileloader = false;
                    this.importareafile = "";
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },
        getloksabha(ev) {
            this.ApiService.getloksabha(ev).then((data) => {
                if (data.status == 200) {
                    this.loksabhaList = data.data;
                } else {
                    this.loksabhaList = '';
                }
            });
        },
        getAreaList() {
            this.loading = true;
            this.ApiService.getAreaList({
                page_no: this.page_no, cityfilter: this.cityFilter,
                pincodefilter: this.pincodeFilter,
                areafilter: this.areaFilter
            }).then((data) => {
                if (data.status == 200) {
                    this.areaList = data.result;
                    this.totalRecords = data.data;
                    this.loading = false;
                } else {
                    this.areaList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAreaList({ page_no: event.page, statefilter: this.event_state, loksabhafilter: this.event_loksabha });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        onFilter() {
            this.isFilterDialog = true;
        },
        getFilteredData() {
            this.page_no = 0;
            this.loading = true;
            // this.cityFilter = event_state;
            // this.loksabha_fks = event_loksabha;
            this.getAreaList({
                cityfilter: this.cityFilter,
                pincodefilter: this.pincodeFilter,
                areafilter: this.areaFilter
            });
            this.showFillFilter = !!(this.cityFilter || this.pincodeFilter || this.areaFilter);
            this.isFilterDialog = false;
        },
        reSetFilteredData() {
            this.cityFilter = "";
            this.pincodeFilter = '';
            this.areafilter = '';
            this.getAreaList();
            this.showFillFilter = false;
            this.isFilterDialog = false;
        }
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
